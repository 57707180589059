import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useGetSolutionQuery } from '../../redux/apiSlice/plainteApiSlice';
import { useGetCanauxReceptionQuery } from "../../redux/apiSlice/plainteApiSlice";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import jsPDF from "jspdf";
import "jspdf-autotable";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 8,
  },
  label: {
    marginBottom: 4,
  },
});

const trySetFont = (doc, font, style) => {
  // Vérifier si la police est disponible
  const fontList = doc.getFontList();
  if (fontList && fontList[font]) {
    doc.setFont(font, style);
  } else {
    // Si la police n'est pas disponible, utiliser une alternative comme "Helvetica"
    doc.setFont("Helvetica", style);
  }
};


export default function LookAtPlaint(props) {
  const { dummyDatas, plainteData, setPlainteData } = props;


  const { data: solution = [],  } =
  useGetSolutionQuery();
  const { data: canaux = [],  } =
  useGetCanauxReceptionQuery();
   
      const solutionView = solution.find((item)=>(item.id_solution == plainteData.id_solution))
    const canalView = canaux.find((item)=>(item.id_canal == parseInt(plainteData.id_canal)))
      
   
 
 
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error("Invalid Date:", timestamp);
      return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };

  function handleImageChange() {
    // console.log("plaintdata " + plainteData.images);

    for (const file of plainteData.images) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      // Use the FileReader's onload event directly
      reader.addEventListener("load", (event) => {
        setUploadedImageUrls(event.target.result);
      });
    }
  }

  const generatePDF = () => {
    const doc = new jsPDF();
    // https://mysqlapinode.leavesmartconsulting.com/pigru/
    // Ajouter une image
    doc.addImage(
      "https://mysqlapinode.leavesmartconsulting.com/file/iso.jpg",
      "JPEG",
      10,
      8,
      30,
      30
    );
    doc.setFont("garamond");
    // Texte "Date de soumission:XX/XX/XX" sous l'image
    doc.setFontSize(13);
    doc.text(`Date de soumission : ${formatDate(new Date())}`, 14, 46);

    // Rectangle avec remplissage bleu
    doc.setFillColor("#1a588f"); // Couleur bleue
    doc.rect(50, 27, 130, 10, "F"); // Rectangle avec remplissage

    // Texte "FICHE DE PLAINTE" en blanc
    doc.setTextColor(255); // Blanc
    doc.setFontSize(14);
    doc.text("FICHE DE PLAINTE", 88, 34);

    // Titre du PDF
    doc.setTextColor(0); // Noir
    doc.setFillColor(26, 88, 143); // Couleur bleue
    doc.setFontSize(13);
    doc.text("1- PREALABLES SUR LA PLAINTE", 20, 55);

    // Générer le tableau dans le PDF
    const columnStyles = { 0: { cellWidth: 70 }, 1: { cellWidth: 115 } };
    doc.autoTable({
      body: [
        ["Référence de la plainte chez l'opérateur", plainteData?.ref_plt],
        [
          "Réponse de l'opérateur",
          plainteData?.recours === "0"
            ? "Non (moins d'un mois)"
            : "Oui mais pas satisfait(e)",
        ],
       
        [
          "Opérateur concerné",
          dummyDatas?.nom_operateur,
        ],
        ["Canal de retour", canalView.lib_canal],
      ],
      columnStyles,
      startY: 60,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 12, halign: "left", font: "garamond" },
    });

    // Titre du tableau 2
    doc.text(
      "2- INFORMATIONS SUR LE PLAIGNANT",
      20,
      doc.autoTable.previous.finalY + 13
    );

    doc.autoTable({
      body: [
        [
          "Nature du plaignant:",
          plainteData.type_personne === "0" ? "Personne physique" : "Personne morale",
        ],
        ["Raison sociale", plainteData?.type_personne !== "0" ? plainteData?.raison : "N/A"],
        ["Nom du plaignant", plainteData?.nom_plt],
        ["Prénom(s) du plaignant", plainteData?.prenom_plt],
        ["Sexe", plainteData?.sexe],
        ["Téléphone", plainteData?.tel_plt],
        ["Email", plainteData?.email_plt],
        ["Adresse de résidence", plainteData?.adresse_plt],
        ["Nationalite", plainteData.nationalite],
        ["Profession", plainteData.profession],
      ],
      columnStyles,
      startY: doc.autoTable.previous.finalY + 20,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 12, halign: "left", font: "garamond" },
    });

    // Titre du tableau 3
    const formateDatePlainte = (laDate) => {
      const dateObject = new Date(laDate);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    };
    doc.text("3- DETAILS DE LA PLAINTE", 20, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      body: [
        ["Résumé de la plainte", plainteData?.desc_plt],
        ["Solution attendue", plainteData?.id_solution == 0 ? plainteData?.lib_solution : solutionView?.lib_solution],
        ["Date du premier constat",  plainteData?.date_constat == null ? "-" : formateDatePlainte(plainteData?.date_constat)],
        ["Canal de retour souhaité", plainteData?.canal_retour],
      ],
      columnStyles,
      startY: doc.autoTable.previous.finalY + 20,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 12, halign: "left", font: "garamond" },
    });

    // Sauvegarder le PDF
    doc.save("fiche_de_plainte.pdf");
  };

  return (
    <div className="container">
      <br />
      <br />
      <br />
      <button
        className="m-btn m-btn-border cta__btn active m-btn-border-3"
        onClick={generatePDF}
        style={{}}
      >
        Générer PDF
      </button>

      {/* <div className="container">
        <PDFViewer width="100%" height="600px">
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.heading}>Nom et prénom(s)</Text>
                <Text style={styles.label}> {plainteplainteData?.nom_plt} </Text>
                <Text style={styles.label}> {plainteData?.prenom_plt} </Text>
                <Text style={styles.heading}>Type de plainte</Text>
                <Text style={styles.label}> {dummyDatas?.type_plainte} </Text>

                <Text style={styles.heading}>Opérateur Concerné</Text>
                <Text style={styles.label}>{dummyDatas?.nom_operateur}</Text>

                <Text style={styles.heading}>Service concerné</Text>
                <Text style={styles.label}> {dummyDatas?.lib_service}</Text>

                <Text style={styles.heading}>Type de personne</Text>
                <Text style={styles.label}>
                  {plainteData?.type_personne === "0"
                    ? "Personne physique"
                    : "Personne morale"}
                </Text>

                <Text style={styles.heading}>Email</Text>
                <Text style={styles.label}> {plainteData?.email_plt}</Text>

                <Text style={styles.heading}>Téléphone</Text>
                <Text style={styles.label}>{plainteData?.tel_plt}</Text>

                <Text style={styles.heading}>Adresse géographique</Text>
                <Text style={styles.label}> {plainteData?.adresse_plt}</Text>

                <Text wrap style={styles.heading}>
                  Recours fait à l'operateur ?
                </Text>
                <Text> {"Oui"}</Text>

                <Text wrap style={styles.heading}>
                  Réponse de la part de l’opérateur ?
                </Text>
                <Text> {"Oui"}</Text>
              </View>

              <View style={styles.section}>
                <Text style={styles.heading}>
                  Référence de la plainte déposée auprès de l’opérateur
                </Text>
                <Text> {plainteData?.ref_plt}</Text>

                <Text wrap style={styles.heading}>
                  Résumé de la plainte
                </Text>
                <Text> {plainteData?.desc_plt}</Text>

                <Text style={styles.heading}>Date du premier constat</Text>
                <Text> {plainteData?.date_constat}</Text>

                <Text style={styles.heading}>Canal de retour souhaité</Text>
                <Text>
                  {plainteData?.canal_retour === "email"
                    ? "E-mail"
                    : "Téléphone"}
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div> */}
    </div>
  );
}
